import NavBar from "../components/NavBar";
import SideBar from "../components/SideBar";
import { Outlet, useNavigate } from "react-router-dom";
import SideBarMobile from "../components/SideBar/SideBarMobile";
import { useAuth } from "../hooks/useAuth";
import { useEffect } from "react";



export default function DashboardLayout() {

    const { isAuth } = useAuth()

    const navigate = useNavigate()

    /**
     * redirect none auth users to the home page
     */
    useEffect(() => {
        const checkAuthStatus = () => {
            if (!isAuth) {
                navigate('/auth/login')
            }
        };

        checkAuthStatus();
    }, [isAuth, navigate])

    return (
        <main className="flex flex-col text-primaryTextColor font-body">
            <NavBar />

            <section className="w-full max-w-full flex max-lg:flex-col-reverse">
                <SideBar />
                <div
                    className="bg-white h-full min-h-screen flex flex-col relative grow justify-between">
                    <Outlet />
                </div>
            </section>

            <SideBarMobile />
        </main>
    )
}