import { isNull } from "../../../Helpers/utils"



export default function AuthInput({
    type="text",
    placeholder="",
    value="",
    change= () => {},
    error=null,
    readOnly = false
}) {

    const className = `rounded-lg outline-none bg-primary/[0.03] py-3 px-4 border ${!isNull(error) ? 'border-red-500' : 'border-borderColor hover:border-primary focus:border-primary'}`;

    return (
        <label className="flex flex-col gap-1 transition-all duration-300">
            <input
                type={type}
                className={className}
                placeholder={placeholder}
                onChange={change}
                value={value}
                readOnly={readOnly}
            />
            {!isNull(error) && <p className="textBody-xs3 text-red-500">{error}</p>}
        </label>
    )
}