import { Link, useLocation } from "react-router-dom";
import { Appointments, Dashboard, Salon, Share } from "../../icons";
import { useAuth } from "../../hooks/useAuth";
import { getFullName, renderImage } from "../../Helpers/utils";
import AvatarLoader from "../Shared/Avatar/AvatarLoader";
import Avatar from "../Shared/Avatar";



export default function SideBarMobile() {

    const { authUser } = useAuth();

    const { data: user, isLoading } = authUser ?? {};

    // Get path name
    const location = useLocation();
    const { pathname } = location;


    const isActive = (path) => {
        return path === pathname;
    }

    return (
        <>
            <div className="sticky bottom-0 lg:hidden">

                <aside className={`shrink-0 text-white flex items-center gap-2 justify-between textBody-s3 bg-primary2 scrollBar1 transition-all duration-300 z-[1001] w-full`}>
                    <Link to={`/dashboard`}>
                        <div className={`p-3 flex items-center gap-2 text-white transition-colors duration-200 cursor-pointer border-t-2 ${isActive('/dashboard') ? 'border-blue-500 bg-white/5' : 'border-transparent'}`}>
                            <div className="flex shrink-0 justify-center items-center w-9 h-9">
                                <Dashboard className="w-6 h-6" />
                            </div>
                        </div>
                    </Link>
                    <Link to={`/dashboard/appointments`}>
                        <div className={`p-3 flex items-center gap-2 text-white transition-colors duration-200 cursor-pointer border-t-2 ${isActive('/dashboard/appointments') ? 'border-blue-500 bg-white/5' : 'border-transparent'}`}>
                            <div className="flex shrink-0 justify-center items-center w-9 h-9">
                                <Appointments className="w-6 h-6" />
                            </div>
                        </div>
                    </Link>
                    <Link to={`/dashboard/salon`}>
                        <div className={`p-3 flex items-center gap-2 text-white transition-colors duration-200 cursor-pointer border-t-2 ${isActive('/dashboard/salon') ? 'border-blue-500 bg-white/5' : 'border-transparent'}`}>
                            <div className="flex shrink-0 justify-center items-center w-9 h-9">
                                <Salon className="w-6 h-6" />
                            </div>
                        </div>
                    </Link>
                    <Link to={`/dashboard/share`}>
                        <div className={`p-3 flex items-center gap-2 text-white transition-colors duration-200 cursor-pointer border-t-2 ${isActive('/dashboard/share') ? 'border-blue-500 bg-white/5' : 'border-transparent'}`}>
                            <div className="flex shrink-0 justify-center items-center w-9 h-9">
                                <Share className="w-6 h-6" />
                            </div>
                        </div>
                    </Link>

                    {/* <button className="outline-none">
                        <div className={`p-3 flex items-center gap-2 text-white transition-colors duration-200 bg-transparent cursor-pointer`}>
                            <div className="flex shrink-0 justify-center items-center w-9 h-9">
                                <AddNew2 className="w-6 h-6" />
                            </div>
                        </div>
                    </button> */}

                    {/* <Link to={`/payments`}>
                        <div className={`p-3 flex items-center gap-2 text-white transition-colors duration-200 ${isActive('/payments') ? 'bg-primary cursor-pointer' : 'bg-transparent cursor-pointer'}`}>
                            <div className="flex shrink-0 justify-center items-center w-9 h-9">
                                <Payments className="w-6 h-6" />
                            </div>
                        </div>
                    </Link> */}

                    {/* <button className="outline-none">
                        <div className={`p-3 flex items-center gap-2 text-white transition-colors duration-200 bg-transparent cursor-pointer`}>
                            <div className="flex shrink-0 justify-center items-center w-9 h-9">
                                <Menu className="w-6 h-6" />
                            </div>
                        </div>
                    </button> */}

                    <Link to={`/dashboard/account`}>
                        <div className={`p-3 flex items-center gap-2 text-white transition-colors duration-200 cursor-pointer border-t-2 ${isActive('/dashboard/account') ? 'border-blue-500 bg-white/5' : 'border-transparent'}`}>
                            {isLoading
                                ? <AvatarLoader size={'w-9 h-9'} />
                                : <Avatar
                                    src={renderImage(user?.avatar)}
                                    size={'w-9 h-9'}
                                    alt={getFullName(user)}
                                    style={{ cursor: 'pointer' }}
                                />
                            }
                        </div>
                    </Link>

                </aside>
            </div>
        </>
    )
}