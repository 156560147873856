import { useEffect, useState } from "react"
import { useAuth } from "../../../hooks/useAuth"
import { Spinner } from "../../../icons"
import axiosClient from "../../../lib/axiosClient";
import AuthButton from "../../../components/Shared/Button/AuthButton";
import AuthInput from "../../../components/Shared/Input/AuthInput";
import { isNull } from "../../../Helpers/utils";

export default function ConfirmationEmail() {

    const { csrf, authUser } = useAuth()


    const { email: currentEmail } = authUser?.data ?? {};


    const [email, setEmail] = useState('')
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState([])


    useEffect(() => {
        setEmail(currentEmail ?? '')
    }, [currentEmail])


    // on submit form
    const onSubmit = async (e) => {
        e.preventDefault()

        if (isNull(email)) return;

        try {
            setLoading(true)
            setErrors([])

            await csrf()


            const { data } = await axiosClient.post('api/auth/confirmation/email/sendLink', { email });


            if (data?.error) {
                // error
                setErrors({ danger: data?.message ?? 'Error!' })
            } else {
                // success
                setErrors({ success: data?.message ?? 'Success!' })
            }


            setLoading(false)
        } catch (err) {
            setErrors({ danger: err?.response?.data?.message ?? 'Error!' })
            setLoading(false)
        }
    }

    return (
        <div className="my-16 py-4 max-w-6xl mx-auto px-4">
            <div className="w-full max-w-md mx-auto py-20">
                {authUser.isLoading ? <Spinner className="w-12 h-12 text-primary2 animate-spin mx-auto" /> :
                    <form onSubmit={onSubmit} className="w-full">
                        <div className="mb-8 flex-col gap-4">
                            <h2 className="textHeader-m">Confirm Your Email Address</h2>
                            <p className="textBody-s3">Enter your email address to send a confirmation link to you.</p>
                        </div>
                        <div className="mb-4 mt-4 flex flex-col gap-2">
                            <label className="textBody-s2">Email address</label>
                            <AuthInput
                                type="email"
                                placeholder="example@gmail.com"
                                value={email}
                                change={(e) => setEmail(e.target.value)}
                            />
                        </div>

                        {errors.success && (
                            <div className="bg-green-500 text-white py-2 lg:py-4 px-2 sm:px-4 lg:px-6 w-full rounded-lg mb-4">
                                <span>{errors.success}</span>
                            </div>
                        )}

                        {errors.danger && (
                            <div className="bg-red-500 text-white py-2 lg:py-4 px-2 sm:px-4 lg:px-6 w-full rounded-lg mb-4">
                                <span>{errors.danger}</span>
                            </div>
                        )}

                        <AuthButton
                            type="submit"
                            name="Send verification link"
                            disabled={loading}
                            isLoading={loading}
                        />
                    </form>}
            </div>
        </div>
    )
}