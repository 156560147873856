import { Link } from "react-router-dom";
import { ArrowRight1 } from "../../icons";

export default function Auth() {
    return (
        <div className="my-16 py-4 max-w-6xl mx-auto px-4">
            <div className="w-full max-w-md mx-auto py-20">
                <div className="">
                    <h2 className="textHeader-s">Create an account</h2>
                </div>

                <div className="w-full flex flex-col gap-4 my-3 py-3">
                    <Link to="/auth/register?t=clients">
                        <div className="w-full rounded-lg cursor-pointer py-6 px-5 shadow-sm border hover:bg-primary/[0.03] transition-all duration-200">
                            <div className="flex items-center justify-between gap-2">
                                <div className="flex flex-col gap-1">
                                    <span className="textBody-m1">For Clients</span>
                                    <p className="textBody-s3 text-fontColor2">Book top barbers near you.</p>
                                </div>

                                <ArrowRight1 className="w-5 h-5" />
                            </div>
                        </div>
                    </Link>

                    <Link to="/auth/register?t=barbers">
                        <div className="w-full rounded-lg cursor-pointer py-6 px-5 shadow-sm border hover:bg-primary/[0.03] transition-all duration-200">
                            <div className="flex items-center justify-between gap-2">
                                <div className="flex flex-col gap-1">
                                    <span className="textBody-m1">For Barbers</span>
                                    <p className="textBody-s3 text-fontColor2">Grow and manage your business easily.</p>
                                </div>

                                <ArrowRight1 className="w-5 h-5" />
                            </div>
                        </div>
                    </Link>
                </div>


                {/* <div className="">
                    <p className="lg:textBody-m3 textBody-s3 text-fontColor2">You have an account? <Link to="/auth/login" className="hover:underline">Sign in</Link></p>
                </div> */}
            </div>
        </div>
    )
}