import { Spinner } from "../../../icons"


export default function AuthButton({
    type = "button",
    name = "Button",
    onClick = () => { },
    disabled = false,
    isLoading = false,
}) {

    // render colors style style
    const renderStyleColors = () => {
        return `bg-primary border-[1.5px] border-primary text-white ${disabled ? 'opacity-90' : 'hover:bg-primary/80 hover:border-primary/80'}`;
    }

    return (
        <div className="flex flex-col">
            <button
                onClick={onClick}
                type={type}
                disabled={disabled}
                className={`rounded-lg py-3 px-6 transition-all duration-100 ease-in flex items-center justify-center gap-2 textBody-m1 ${disabled ? '' : 'active:scale-[.99]'} ${renderStyleColors()}`}>
                {isLoading
                    ? <Spinner className="w-6 h-6 animate-spin" />
                    : name}
            </button>
        </div>
    )
}