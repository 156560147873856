import SalonCard from "../../../components/Pages/SalonInformations/SalonCard";
import { GetSalonByMember } from "../../../lib/api";
import { useAlerts } from "../../../context/AlertsContext";



const SalonInformations = () => {

    const { addAlert } = useAlerts();

    const { data, loading, refresh } = GetSalonByMember();

    return (
        <div className="xl:p-8 lg:p-6 sm:p-4 p-2">
            <div className="w-full max-w-5xl mx-auto">
                <div className="mb-3 pb-3">
                    <h2 className="textHeader-s">Salon Informations</h2>
                </div>

                <SalonCard
                    loading={loading}
                    salonData={data?.data}
                    refresh={refresh}
                    addAlert={addAlert}
                />
            </div>
        </div>
    )
}

export default SalonInformations;