import { isNull } from "../../../../Helpers/utils";
import { Spinner } from "../../../../icons";
import dayjs from "../../../../lib/dayjs";

export default function BookNowButton({
    selectedDate,
    selectedTime,
    canBook,
    isLoading,
    onClick,
}) {

    // const getTodate = dayjs(new Date()).format('DD-MM-YYYY');
    const isToday = dayjs(selectedDate).isSame(dayjs(), 'day');
    const diff = dayjs(selectedDate).diff(dayjs(), 'day');

    const getDateLabel = () => {
        if (isToday) return 'Today';
        if (diff === 0) return 'Tomorrow';
        return `After ${diff} ${diff === 1 ? 'day' : 'days'}`;
    };

    const getTimeLabel = (selectedDateVal, selectedTimeVal) => {
        // Ensure selectedDateVal is a valid dayjs object
        const selectedDate = dayjs(selectedDateVal);

        // Combine selectedDate and selectedTime into one dayjs object
        const selectedDateTime = dayjs(`${selectedDate.format('YYYY-MM-DD')} ${selectedTimeVal.replace(' PM', '').replace(' AM', '')}`);
        const now = dayjs();  // Current date and time

        if (selectedDateTime.isBefore(now)) {
            return 'Time has passed'; // Handle past times
        }

        // Calculate the difference in hours and minutes
        const diffInMinutes = selectedDateTime.diff(now, 'minute');
        const hours = Math.floor(diffInMinutes / 60);  // Get full hours
        const minutes = diffInMinutes % 60;            // Remaining minutes

        // Create label based on hours and minutes
        if (hours > 0 && minutes > 0) {
            return `After ${hours} hours and ${minutes} minutes`;
        } else if (hours > 0) {
            return `After ${hours} hours`;
        } else {
            return `After ${minutes} minutes`;
        }
    };

    return (
        <div className="sticky bottom-0 shadow w-full bg-white border-t border-borderColor">
            <div className="w-full max-w-5xl mx-auto px-4 py-3 flex items-center gap-4">
                <button
                    disabled={isLoading || !canBook}
                    onClick={onClick}
                    className={`outline-none whitespace-nowrap rounded-md textBody-s2 py-3.5 px-6 transition-colors duration-200 select-none ${canBook ? 'bg-primary hover:bg-primary2 text-white' : 'bg-primary/10 cursor-context-menu text-primary/50'} `}>
                    {isLoading
                        ? <Spinner className="w-5 h-5 animate-spin" />
                        : 'Book now'
                    }
                </button>

                <div className="flex flex-col gap-0.5">
                    <span className="textBody-s2 text-fontColor2">
                        {dayjs(selectedDate).format('MMMM DD, YYYY')}
                        <span className="text-fontColor2/60 ml-2">({getDateLabel()})</span>
                    </span>
                    {!isNull(selectedTime) &&
                        <span className="textBody-s2 text-fontColor2">
                            {selectedTime}
                            <span className="text-fontColor2/60 ml-2">({getTimeLabel(dayjs(selectedDate).format('YYYY-MM-DD'), selectedTime)})</span>
                        </span>}
                </div>
            </div>
        </div>
    )
}