
export const registerFormValidator = (data) => {
    const newErrors = {};

    // Validation for first name
    if (!data.firstname || typeof data.firstname !== "string" || data.firstname.length > 20 || !/^[a-zA-Z]+$/.test(data.firstname)) {
        newErrors.firstname = "First name is required and must be a string with a maximum length of 20 characters, containing only letters (no spaces or numbers).";
    }

    // Validation for last name
    if (!data.lastname || typeof data.lastname !== "string" || data.lastname.length > 20 || !/^[a-zA-Z]+$/.test(data.lastname)) {
        newErrors.lastname = "Last name is required and must be a string with a maximum length of 20 characters, containing only letters (no spaces or numbers).";
    }

    // Validation for email
    if (!data.email || typeof data.email !== "string" || data.email.length > 255 || /\s/.test(data.email)) {
        newErrors.email = "Email is required, must be a string with a maximum length of 255 characters, and cannot contain spaces.";
    } else if (!/\S+@\S+\.\S+/.test(data.email)) {
        newErrors.email = "Email must be a valid email address.";
    }

    // Validation for password
    if (!data.password || typeof data.password !== "string" || data.password.length < 3 || data.password.length > 20) {
        newErrors.password = "Password is required and must be a string between 3 and 20 characters.";
    }

    return newErrors;
};

export const loginFormValidator = (data) => {
    const newErrors = {};

    // Validation for email
    if (!data.email || typeof data.email !== "string" || data.email.length > 255 || /\s/.test(data.email)) {
        newErrors.email = "Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(data.email)) {
        newErrors.email = "Email must be a valid email address.";
    }

    // Validation for password
    if (!data.password || typeof data.password !== "string") {
        newErrors.password = "Password is required.";
    }

    return newErrors;
};