import { GetSalonByMember } from "../../../lib/api";
import { useAlerts } from "../../../context/AlertsContext";
import { QRCodeSVG } from 'qrcode.react';
import { Copy } from "../../../icons";
import ShareBtn from "./ShareBtn";



export default function ShareSalon() {

    const { addAlert } = useAlerts();

    const { data, loading } = GetSalonByMember();


    const salonUrl = process.env.REACT_APP_FRONTEND_URL + data?.data?.slug ?? '#';



    const handleCopyLink = () => {
        navigator?.clipboard?.writeText(salonUrl)
        addAlert('Copied to clipboard!', 'success')
    }


    return (
        <div className="xl:p-8 lg:p-6 sm:p-4 p-4 mt-6">
            <div className="w-full max-w-5xl mx-auto text-center">
                <div className="mb-3 pb-3">
                    <h2 className="textHeader-s">Share with your clients</h2>
                </div>

                {loading ? suspense() :
                    <>
                        <div className="w-fit p-2 border border-borderColor rounded-lg mx-auto mt-4 mb-6">
                            <QRCodeSVG
                                value={salonUrl}
                                size={200}
                            />
                        </div>
                        <div className="max-w-md mx-auto flex flex-col gap-2">
                            <div className="w-full mx-auto flex gap-2">
                                <input
                                    onClick={handleCopyLink}
                                    type="text"
                                    value={salonUrl}
                                    readOnly
                                    className="outline-none w-full py-2 px-3 border border-[#dddcdc] rounded-lg"
                                />
                                <button
                                    onClick={handleCopyLink}
                                    className="outline-none w-fit py-2 px-3 border border-[#dddcdc] rounded-lg hover:bg-primary/[0.03]"
                                >
                                    <Copy className="w-4 h-4" />
                                </button>
                            </div>

                            <ShareBtn
                                link={salonUrl}
                                handleCopyLink={handleCopyLink}
                            />
                        </div>
                    </>}
            </div>
        </div>
    )
}

const suspense = () => {
    return (
        <div className="animate-pulse">
            <div className="p-2 border border-borderColor rounded-lg mx-auto mt-4 mb-6 bg-primary/[0.03] size-[200px]"></div>
            <div className="max-w-md mx-auto flex flex-col gap-2">
                <div className="w-full mx-auto flex gap-2">
                    <div className="w-full size-11 py-2 px-3 border border-[#dddcdc] bg-primary/[0.03] rounded-lg"></div>
                    <div className="size-11 py-2 px-3 border border-[#dddcdc] bg-primary/[0.03] rounded-lg"></div>
                </div>

                <div className="w-full size-11 py-2 px-3 border border-[#dddcdc] bg-primary/[0.03] rounded-lg"></div>
            </div>
        </div>
    )
}