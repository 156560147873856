import { useState } from "react";
import { isNull, n, renderImage } from "../../../Helpers/utils";


export default function Content({ loading, appointments }) {

    const [selectedApp, setSelectedApp] = useState('')
    const selectApp = (ndx) => {
        if (!isNull(selectedApp) && n(selectedApp) === ndx) {
            setSelectedApp("")
        } else {
            setSelectedApp(ndx)
        }
    }

    return (
        <div className="h-full min-h-screen flex flex-col relative grow justify-between">
            <div className="flex flex-col gap-3">
                {loading ? suspense() :
                    isNull(appointments) ? noResults() :
                        appointments?.map((appo, index) => {

                            const { id, booking_date, booking_time, booking_status, salon } = appo;

                            const isSelected = !isNull(selectedApp) && n(selectedApp) === id;

                            return (
                                <div
                                    key={index}
                                    onClick={() => selectApp(id)}
                                    className={`rounded-lg py-4 px-4 cursor-pointer select-none transition-colors duration-200 overflow-hidden
                            ${isSelected ? 'bg-primary/[0.02] border :border-primary/5' : 'hover:bg-primary/[0.03] border border-borderColor hover:border-primary/5'}
                            `}>
                                    <div className="flex justify-between">
                                        <div className="">
                                            <div className="flex items-center gap-3">
                                                <div>
                                                    <div
                                                        className="w-16 h-16 bg-primary/10 bg-cover bg-center rounded-lg mx-auto"
                                                        style={{ backgroundImage: `url("${renderImage(salon?.images)}")` }}
                                                    >
                                                    </div>
                                                </div>
                                                <div className="flex flex-col">
                                                    <span className="textBody-s2 text-fontColor line-clamp-1">{salon?.name}</span>
                                                    <div className="text-fontColor2">
                                                        <span className="textBody-xs3 inline-block mr-2">{booking_date}</span>
                                                        <span className="textBody-xs3 inline-block">{booking_time}</span>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* // */}
                                        </div>

                                        <div className="">
                                            <span className={`textBody-xs2 px-2 py-0.5 rounded-xl border ${renderAppoStatus(booking_status)}`}>{booking_status}</span>
                                        </div>
                                    </div>
                                    <div className={`pt-4 mt-4 border-t border-borderColor transition-all duration-300 ${isSelected ? 'block' : 'hidden'}`}>
                                        <div className="">
                                            <span className="textBody-s2 text-fontColor border-b pb-1">Details</span>
                                        </div>
                                        <div className="ml-0 mt-2 textBody-xs3">

                                            <div className="grid grid-cols-2 gap-1 pt-3">
                                                <div className="">
                                                    <span className="text-fontColor">Salon:</span>
                                                </div>
                                                <div className="">
                                                    <span className="text-fontColor2">{salon?.name}</span>
                                                </div>
                                            </div>
                                            <div className="grid grid-cols-2 gap-1 pt-3">
                                                <div className="">
                                                    <span className="text-fontColor">Booking date:</span>
                                                </div>
                                                <div className="">
                                                    <span className="text-fontColor2">{booking_date}</span>
                                                </div>
                                            </div>
                                            <div className="grid grid-cols-2 gap-1 pt-3">
                                                <div className="">
                                                    <span className="text-fontColor">Booking time:</span>
                                                </div>
                                                <div className="">
                                                    <span className="text-fontColor2">{booking_time}</span>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            )
                        })}
            </div>
        </div>
    )
}

const renderAppoStatus = (stts) => {
    switch (stts) {
        case 'active':
            return "text-green-500 border-green-200 bg-green-100";
        case 'confirmed':
            return "text-blue-500 border-blue-200 bg-blue-100";
        case 'canceled':
            return "text-red-500 border-red-200 bg-red-100";
        case 'completed':
            return "text-slate-500 border-slate-200 bg-slate-100";
        default:
            return "text-blue-500 border-blue-200 bg-blue-100";
    }
}

const suspense = () => {
    return (
        <>
            {[1, 2, 3, 4, 5, 6].map((n, index) => (
                <div
                    key={index}
                    className="rounded-lg py-4 px-4 cursor-pointer select-none transition-colors duration-200 overflow-hidden bg-primary/[0.03] border border-borderColor animate-pulse">
                    <div className="flex justify-between">
                        <div className="">
                            <div className="flex items-center gap-3">
                                <div>
                                    <div className="w-16 h-16 bg-primary/5 bg-cover bg-center rounded-lg mx-auto"></div>
                                </div>
                                <div className="flex flex-col gap-3">
                                    <div className="bg-primary/5 h-6 w-28 rounded-xl"></div>
                                    <div className="flex items-center gap-3">
                                        <div className="bg-primary/5 h-4 w-28 rounded-xl"></div>
                                        <div className="bg-primary/5 h-4 w-28 rounded-xl"></div>
                                    </div>
                                </div>
                            </div>

                            {/* // */}
                        </div>

                        <div className="">
                            <div className="bg-primary/5 h-5 w-16 rounded-xl"></div>
                        </div>
                    </div>
                </div>
            ))}
        </>
    )
}

const noResults = () => {
    return (
        <div className="grid gap-4 w-full border border-borderColor rounded-lg py-6 px-4">
            <div className="w-20 h-20 mx-auto bg-gray-50 rounded-full shadow-sm justify-center items-center inline-flex">
                <svg xmlns="http://www.w3.org/2000/svg" width="33" height="32" viewBox="0 0 33 32" fill="none">
                    <g id="File Serch">
                        <path id="Vector" d="M19.9762 4V8C19.9762 8.61954 19.9762 8.92931 20.0274 9.18691C20.2379 10.2447 21.0648 11.0717 22.1226 11.2821C22.3802 11.3333 22.69 11.3333 23.3095 11.3333H27.3095M18.6429 19.3333L20.6429 21.3333M19.3095 28H13.9762C10.205 28 8.31934 28 7.14777 26.8284C5.9762 25.6569 5.9762 23.7712 5.9762 20V12C5.9762 8.22876 5.9762 6.34315 7.14777 5.17157C8.31934 4 10.205 4 13.9762 4H19.5812C20.7604 4 21.35 4 21.8711 4.23403C22.3922 4.46805 22.7839 4.90872 23.5674 5.79006L25.9624 8.48446C26.6284 9.23371 26.9614 9.60833 27.1355 10.0662C27.3095 10.524 27.3095 11.0253 27.3095 12.0277V20C27.3095 23.7712 27.3095 25.6569 26.138 26.8284C24.9664 28 23.0808 28 19.3095 28ZM19.3095 16.6667C19.3095 18.5076 17.8171 20 15.9762 20C14.1352 20 12.6429 18.5076 12.6429 16.6667C12.6429 14.8257 14.1352 13.3333 15.9762 13.3333C17.8171 13.3333 19.3095 14.8257 19.3095 16.6667Z" stroke="#4F46E5" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
                    </g>
                </svg>
            </div>
            <div>
                <h2 className="text-center textBody-s2 pb-1 text-fontColor2">No Appointments Found!</h2>
            </div>
        </div>
    )
}