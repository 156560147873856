import { Link } from "react-router-dom";

export default function SideBar({ stats, appoType }) {

    const { all, active, confirmed, completed, canceled } = stats ?? {};

    const asideLinks = [
        {
            label: 'All',
            path: '/dashboard/appointments',
            param: 'all',
            total: all ?? 0,
        }, {
            label: 'Active',
            path: '/dashboard/appointments?status=active',
            param: 'active',
            total: active ?? 0,
        }, {
            label: 'Confirmed',
            path: '/dashboard/appointments?status=confirmed',
            param: 'confirmed',
            total: confirmed ?? 0,
        }, {
            label: 'Completed',
            path: '/dashboard/appointments?status=completed',
            param: 'completed',
            total: completed ?? 0,
        }, {
            label: 'Canceled',
            path: '/dashboard/appointments?status=canceled',
            param: 'canceled',
            total: canceled ?? 0,
        },
    ];

    const isActive = (param) => {
        return param === appoType;
    }

    return (
        <div className="lg:w-[200px] w-full">

            <div className="flex lg:flex-col gap-1 overflow-x-auto select-none">
                {asideLinks.map((link, index) => {
                    return (
                        <Link to={link?.path} key={index} className="flex flex-col">
                            <div className={`flex items-center justify-between gap-2 lg:py-3 py-1.5 px-4 lg:rounded-lg rounded-full text-fontColor2 transition-colors duration-200 cursor-pointer
                                ${isActive(link?.param) ? 'bg-primary/5' : 'bg-transparent hover:bg-primary/5'}
                                `}>
                                <span className="whitespace-nowrap textBody-s3">{link.label}</span>
                                <span className="whitespace-nowrap textBody-s3">{link.total}</span>
                            </div>
                        </Link>
                    )
                })}
            </div>
        </div>
    )
}