import About from "../../components/Pages/Home/About";
import Footer from "../../components/Pages/Home/Footer";
import Welcome from "../../components/Pages/Home/Welcome";


const HomePage = () => {
    return (
        <>
            <Welcome />

            <About />

            <Footer />
        </>
    )
}

export default HomePage;