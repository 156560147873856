import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useAuth } from "../hooks/useAuth";
import AvatarDropDown from "../components/Pages/Home/Header/AvatarDropDown";


export default function AuthLayout() {

    const { authUser, isAuth, logout, isLoading } = useAuth()


    const { role, isEmailVerified } = authUser?.data ?? {};


    // handle logout click
    const handleLogout = async () => {
        await logout()
    }


    const navigate = useNavigate()


    // Get path name
    const location = useLocation();
    const { pathname } = location;


    /**
     * redirect auth users to the dashboard page
     */
    useEffect(() => {
        const checkAuthStatus = () => {
            if (isAuth) {
                if (!['/auth/confirmation/email', '/auth/confirmation/email/check'].includes(pathname)) {
                    navigate(role === "member" ? '/dashboard' : '/')
                }
            } else {
                if (['/auth/confirmation/email', '/auth/confirmation/email/check'].includes(pathname)) {
                    navigate('/')
                }
            }
        };

        checkAuthStatus();
    }, [isAuth, navigate, role, isEmailVerified, pathname])

    return (
        <main>
            <header className="w-full border-b">
                <div className="max-w-6xl mx-auto h-[65px] w-full px-4 flex items-center justify-between">
                    <a href="/">
                        <h1 className="textBody-ml1">Sharely</h1>
                    </a>

                    {!isAuth && (
                        <div className="flex items-center gap-4">
                            <Link to="/auth/login" className="hover:underline textBody-s2">Login</Link>
                            <Link to="/auth" className="px-6 py-2.5 hover:bg-primary2 textBody-s2 bg-primary text-white rounded-full transition-colors duration-200">Register</Link>
                        </div>
                    )}

                    {isAuth && (
                        <div className="flex items-center gap-4">
                            <AvatarDropDown authUser={authUser} logout={handleLogout} inProgress={isLoading} role={role} />
                        </div>
                    )}
                </div>
            </header>

            <Outlet />
        </main>
    )
}