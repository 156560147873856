import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import DashboardLayout from './layouts/DashboardLayout';
import NotFound from './components/NotFound';
import Layout from './layouts/Layout';

import ScrollToTop from './components/ScrollToTop';
import { AlertsProvider } from './context/AlertsContext';
import AlertsList from './components/Alerts/AlertsList';
import HomePage from './pages/HomePage';
import Login from './pages/Auth/Login';
import Auth from './pages/Auth';
import Register from './pages/Auth/Register';
import AuthLayout from './layouts/AuthLayout';
import Salon from './pages/Salon';
import ClientAppointments from './pages/ClientPages/ClientAppointments';
import ClientAccount from './pages/ClientPages/ClientAccount';
import ConfirmationEmail from './pages/Auth/confirmation/email';
import CheckEmail from './pages/Auth/confirmation/check';

// import Dashboard from './pages/DashboardPages/Dashboard';
import Appointments from './pages/DashboardPages/Appointments';
import Calendar from './pages/DashboardPages/Calendar';
import SalonInformations from './pages/DashboardPages/SalonInformations';
import AccountProfile from './pages/DashboardPages/AccountProfile';
import ShareSalon from './pages/DashboardPages/ShareSalon';



function App() {
    return (
        <AlertsProvider>
            <AlertsList />
            <BrowserRouter>
                <ScrollToTop />

                <Routes>
                    <Route path="/" element={<Layout />}>
                        <Route index element={<HomePage />} />

                        <Route path='/appointments' element={<ClientAppointments />} />
                        <Route path='/account' element={<ClientAccount />} />
                    </Route>

                    <Route path='/:slug' element={<Salon />} />

                    <Route path="/auth" element={<AuthLayout />}>
                        <Route index element={<Auth />} />
                        <Route path='login' element={<Login />} />
                        <Route path='register' element={<Register />} />
                        <Route path='confirmation/email' element={<ConfirmationEmail />} />
                        <Route path='confirmation/email/check' element={<CheckEmail />} />
                    </Route>

                    <Route path="/dashboard" element={<DashboardLayout />}>
                        {/* <Route index element={<Dashboard />} /> */}

                        <Route index element={<Appointments />} />
                        <Route path='calendar' element={<Calendar />} />
                        <Route path='appointments' element={<Appointments />} />
                        <Route path='account' element={<AccountProfile />} />
                        <Route path='salon' element={<SalonInformations />} />
                        <Route path='share' element={<ShareSalon />} />
                    </Route>

                    <Route path='*' element={<NotFound />}></Route>
                </Routes>
            </BrowserRouter>
        </AlertsProvider>
    );
}

export default App;
