import React from "react"
import { ArrowLeft1, ArrowRight1 } from "../../../icons"

export default function DatePickerCustomHeaderTwoMonth({
    monthDate,
    customHeaderCount,
    decreaseMonth,
    increaseMonth
}) {
    return (
        <div className="flex items-center gap-4 justify-center">
            <button
                aria-label="Previous Month"
                className={
                    " flex items-center justify-center p-3 rounded-full hover:bg-gray-100 text-fontColor"
                }
                onClick={decreaseMonth}
                type="button"
            >
                <ArrowLeft1 className="w-5 h-5" />
            </button>
            <span className="react-datepicker__current-month">
                {monthDate.toLocaleString("en-US", {
                    month: "long",
                    year: "numeric"
                })}
            </span>
            <button
                aria-label="Next Month"
                className=" flex items-center justify-center p-3 hover:bg-gray-100 rounded-full text-fontColor"
                type="button"
                onClick={increaseMonth}
            >
                <ArrowRight1 className="w-5 h-5" />
            </button>
        </div>
    )
}