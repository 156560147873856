import { Link } from "react-router-dom";
import { ArrowBottom } from "../../../../icons";

export default function About() {
    return (
        <section className="max-w-6xl mx-auto w-full px-4 grid lg:grid-cols-2 grid-cols-1 lg:gap-4 gap-20 py-20">
            <div className="flex flex-col justify-center">
                <div className="flex flex-col lg:w-4/5 w-full">
                    <h2 className="textBody-m2">About Us</h2>
                    <p className="textBody-s3 mt-2 mb-6 text-primary/80">At sharely.in, we understand the challenges barbers face in managing their schedules and appointments. Our platform is designed specifically for barbers, making it easy to organize bookings and streamline your workflow. With our user-friendly interface, you can focus on providing exceptional service while we handle the logistics. Join us and experience the future of barbering – where simplicity meets efficiency!</p>

                    <Link
                        to="/auth/register?t=barbers"
                        className="textBody-s2 bg-[#2d54de] text-white py-2.5 px-8 rounded transition-all duration-200 w-fit hover:shadow-[0_0_10px_0_#2d54de]"
                    >
                        Get Started
                    </Link>
                </div>
            </div>

            <div className="">
                <div className="pb-4 flex items-center gap-2 justify-center">
                    <ArrowBottom className="w-5 h-5" />
                    <h3>Simple Booking</h3>
                    <ArrowBottom className="w-5 h-5" />
                </div>
                <div className="shadow-[0_0_10px_0_#2d54de] border-[#2d54de] border-solid border-[2px] min-h-40">
                    <img
                        src="./booking-screenshot.jpg"
                        alt="booking screenshot"
                    />
                </div>
            </div>
        </section>
    )
}