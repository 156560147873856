import { Link } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { useState } from "react";
import { loginFormValidator } from "../../Helpers/validator";
import AuthInput from "../../components/Shared/Input/AuthInput";
import AuthButton from "../../components/Shared/Button/AuthButton";
import { isNull } from "../../Helpers/utils";


export default function Login() {

    const { login, isLoading } = useAuth()

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const [errors, setErrors] = useState({})


    const handleSubmit = async (e) => {
        e.preventDefault()

        setErrors({})

        const formData = {
            email,
            password
        };


        const errz = loginFormValidator(formData);
        setErrors(errz);


        if (!isNull(errz)) return;


        login({
            setErrors,
            email,
            password
        })
    }

    return (
        <div className="my-16 py-4 max-w-6xl mx-auto px-4">
            <div className="w-full max-w-md mx-auto py-20">
                <div className="">
                    <h2 className="textHeader-s">Welcome back</h2>
                </div>

                <form onSubmit={handleSubmit} className="w-full flex flex-col gap-4 my-3 py-3">
                    <AuthInput
                        type="email"
                        placeholder="Email"
                        value={email}
                        change={(e) => setEmail(e.target.value)}
                        error={errors.email ?? null}
                    />
                    <AuthInput
                        type="password"
                        placeholder="Password"
                        value={password}
                        change={(e) => setPassword(e.target.value)}
                        error={errors.password ?? null}
                    />
                    <AuthButton
                        type="submit"
                        name="Continue"
                        disabled={isLoading}
                        isLoading={isLoading}
                    />
                </form>

                <div className="">
                    <p className="lg:textBody-m3 textBody-s3 text-fontColor2">Don't have an account? <Link to="/auth" className="hover:underline">Sign up</Link></p>
                </div>
            </div>
        </div>
    )
}