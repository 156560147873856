import { useState } from "react";
import axiosClient from "../lib/axiosClient"
import { useLocation, useNavigate } from "react-router-dom";
import useSWR from "swr";
import useCookies from "./useCookies";
import { useAlerts } from "../context/AlertsContext";


export const useAuth = () => {


    // Get path name
    const location = useLocation();
    const { pathname } = location;


    const { addAlert } = useAlerts();


    const navigate = useNavigate()

    const { removeCookie, setCookie, getCookie } = useCookies()


    // check if found cookie "isAuth" or not
    const isAuth = getCookie("isAuth");


    // State to store 'isLoading' value, for all requests in this page
    const [isLoading, setIsLoading] = useState(false)



    // get & set sanctum tokens
    const csrf = () => axiosClient.get('sanctum/csrf-cookie');


    /**
     * Get auth user data
     */
    const authUser = useSWR(isAuth ? "api/auth/user" : null, () =>
        axiosClient.get(isAuth ? "api/auth/user" : null)
            .then(async res => {
                const userData = res.data;


                // check if email not confirmed
                if (userData?.isEmailVerified === 0) {
                    if (!['/auth/confirmation/email', '/auth/confirmation/email/check'].includes(pathname)) {
                        navigate('/auth/confirmation/email')
                    }
                } else if (userData?.isEmailVerified === 1) {
                    if (['/auth/confirmation/email', '/auth/confirmation/email/check'].includes(pathname)) {
                        navigate('/')
                    }
                }


                return userData;
            })
            .catch(async error => {
                if (error.response.data.message === 'Unauthenticated.') {
                    removeCookie("isAuth");
                    removeCookie("_uln");

                    navigate('/auth/login')
                }
            })
    );


    /**
     * Login
    */
    const login = async ({ setErrors, ...props }) => {
        try {
            setIsLoading(true)

            setErrors({})

            await csrf();

            const { data } = await axiosClient.post('api/auth/login', props);

            if (data?.is_ok === true) {

                // save isAuth cookie
                setCookie("isAuth", true);

                // user's remember token
                setCookie("_uln", data?.data?.token ?? "");

                // refresh user data
                authUser.mutate()

                addAlert(data?.message ?? 'Success!', 'success');

                
                // navigate
                if (data?.data?.user?.isEmailVerified === 0) {
                    navigate('/auth/confirmation/email')
                } else {
                    navigate(data?.data?.user?.role === "member" ? '/dashboard' : '/')
                }
                
            } else {
                // show danger message
                addAlert(data?.message ?? 'Something was wrong!', 'error');
            }

        } catch (error) {
            // return validation errors
            if (error?.response?.data?.type === "validation") {
                setErrors(error?.response?.data?.errors ?? {});
            }

            else if (error?.response?.data?.is_ok === false) {
                // show danger message
                addAlert(error?.response?.data?.message ?? 'Something was wrong!', 'error');
            }

        } finally {
            setIsLoading(false)
        }

        return {};
    }



    /**
     * Register
    */
    const register = async ({ setErrors, ...props }) => {
        try {
            setIsLoading(true)

            setErrors({})

            await csrf();

            const { data } = await axiosClient.post('api/auth/register', props);

            if (data.is_ok === true) {

                addAlert(data?.message ?? 'Success!', 'success');

                await login({ setErrors, ...props })
            } else {
                addAlert(data?.message ?? 'Something was wrong!', 'error');
            }

        } catch (error) {
            // return validation errors
            if (error?.response?.data?.type === "validation") {
                setErrors(error?.response?.data?.errors ?? {});
            }

            else if (error?.response?.data?.is_ok === false) {
                addAlert(error?.response?.data?.message ?? 'Something was wrong!', 'error');
            }

        } finally {
            setIsLoading(false)
        }
    }


    /**
     * Logout
     */
    const logout = async () => {
        try {
            setIsLoading(true)

            await csrf();

            const { data } = await axiosClient.post('api/auth/logout');

            if (data.is_ok === true) {
                removeCookie("isAuth");
                removeCookie("_uln");

                navigate('/auth/login')
            }

        } catch (error) {
            if (error.response.data.message === 'Unauthenticated.') {
                removeCookie("isAuth");
                removeCookie("_uln");
            }

        } finally {
            setIsLoading(false)
        }
    }


    return {
        isAuth,
        authUser,
        csrf,
        login,
        register,
        isLoading,
        logout
    };
}