import { Time } from "../../../../icons";
import dayjs from "../../../../lib/dayjs";

export default function ChooseTime({
    selectedDate,
    selectedTime,
    setSelectedTime,
}) {

    // Generate time slots starting from 9:00 AM to 12:00 AM (midnight) with 30-minute intervals
    const generateTimeSlots = (startTime, endTime, interval, selectedDate) => {
        let times = [];
        let currentTime = dayjs(startTime);
        const isToday = dayjs().isSame(selectedDate, 'day');  // Check if selected date is today
        const now = dayjs();  // Current time

        while (currentTime.isBefore(endTime) || currentTime.isSame(endTime)) {
            times.push({
                time: currentTime.format('HH:mm'),
                can_book: !(isToday && currentTime.isBefore(now))  // Disable past times if today
            });
            currentTime = currentTime.add(interval, 'minute');
        }

        return times;
    };

    // Usage
    const timeSlots = generateTimeSlots(
        dayjs().set('hour', 9).set('minute', 0),  // Start time: 9:00 AM
        dayjs().set('hour', 23).set('minute', 0), // End time: 11:00 PM
        30,  // 30-minute intervals
        selectedDate
    );

    return (
        <div className="p-4">
            <div className="flex items-center justify-center gap-2">
                <Time className="lg:w-5 lg:h-5 w-4 h-4" />
                <h4 className="lg:textBody-ml2 textBody-ml2 text-fontColor">Choose time</h4>
            </div>

            <div className="mt-4 grid grid-cols-4 sm:gap-2 gap-1 select-none textBody-s3 text-center">
                {timeSlots?.map((t, index) => {

                    const isSelected = selectedTime === t.time;
                    const isBookable = t.can_book;

                    return (
                        <div key={index}>
                            {isBookable ? (
                                <div
                                    onClick={() => setSelectedTime(t.time)}
                                    className={`w-full rounded-lg cursor-pointer py-2.5 px-4 border transition-all duration-200
                                    ${isSelected ? 'bg-[#2d54de] text-white border-white' : 'hover:bg-primary/[0.03] border-borderColor'}
                                    `}>
                                    <span className="textBody-xs3 line-clamp-1">{t.time}</span>
                                </div>
                            ) : (
                                    <div className={`w-full rounded-lg cursor-not-allowed py-2.5 px-4 border transition-all duration-200 border-borderColor text-[#ccc]`}>
                                        <span className="textBody-xs3 line-clamp-1">{t.time}</span>
                                    </div>
                                )}
                        </div>
                    )
                })}
            </div>
        </div>
    )
}