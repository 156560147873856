import { Edit1 } from "../../../icons";
import { useState } from "react";
import Input from "../../Shared/Input";
import FormModal from "../../Shared/FormModal";
import { UpdateUserPassword } from "../../../lib/api";


export default function Security({ authUser, setNotif }) {

    const [isOpen, setIsOpen] = useState(false)
    const toogleModal = () => setIsOpen(!isOpen)

    const { isLoading } = authUser ?? {};

    return (
        <>
            <div className="rounded-lg bg-white border border-primaryBorder/60">
                <div className="lg:p-6 p-4 flex items-center justify-between border-b border-primaryBorder/50">
                    <h3 className="textBody-m1">Security</h3>
                    <button onClick={toogleModal} className="bg-primary/5 border border-primary/10 rounded-full p-2 hover:bg-primary/10 hover:border-primary/15">
                        <Edit1 className="w-4 h-4" />
                    </button>
                </div>
                <div className="lg:p-6 p-4 flex flex-col gap-4">
                    <div className="flex flex-col gap-1">
                        <h4 className="textBody-s1">Password</h4>
                        {!isLoading
                            ? <span className="text-fontColor textBody-s2">*********</span>
                            : <div className="w-44 h-6 rounded-xl bg-fontColor2 animate-pulse"></div>}
                    </div>
                </div>
            </div>

            {isOpen && (
                <UpdateData
                    close={toogleModal}
                    setNotif={setNotif}
                />
            )}
        </>
    )
}


// Edit modal
const UpdateData = ({ close, setNotif }) => {

    const [currentPassword, setCurrentPassword] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const [repeatNewPassword, setRepeatNewPassword] = useState("")

    const [errors, setErrors] = useState({})
    const [inProgress, setInProgress] = useState(false)


    // send request
    const saveChanges = async () => {

        setErrors({})
        setInProgress(true)

        const data = {
            currentPassword,
            newPassword,
            repeatNewPassword,
        };

        const { is_ok, message, type, errors } = await UpdateUserPassword(data);

        setInProgress(false)

        if (type === "validation") {
            setErrors(errors ?? {});
        } else {
            setNotif(message ?? (is_ok ? 'Success!' : 'Something was wrong!'), is_ok ? 1 : 0);
        }

        if (is_ok) close()
    }

    return (
        <FormModal
            close={close}
            title={'Edit Your Password'}
            save={saveChanges}
            isDisabled={inProgress}
            isLoading={inProgress}
        >
            <Input
                type={"password"}
                title={"Current Password"}
                placeholder={"Enter your current password"}
                value={currentPassword}
                change={(e) => setCurrentPassword(e.target.value)}
                error={errors.currentPassword ?? ''}
            />
            <Input
                type={"password"}
                title={"New Password"}
                placeholder={"Enter new password"}
                value={newPassword}
                change={(e) => setNewPassword(e.target.value)}
                error={errors.newPassword ?? ''}
            />
            <Input
                type={"password"}
                title={"Repeat New Password"}
                placeholder={"Enter new password again"}
                value={repeatNewPassword}
                change={(e) => setRepeatNewPassword(e.target.value)}
                error={errors.repeatNewPassword ?? ''}
            />
        </FormModal>
    )
}