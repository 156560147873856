import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth"
import { useState } from "react";
import { registerFormValidator } from "../../Helpers/validator";
import { isNull } from "../../Helpers/utils";
import AuthInput from "../../components/Shared/Input/AuthInput";
import AuthButton from "../../components/Shared/Button/AuthButton";


export default function Register() {

    // get param "user type"
    const [searchParams] = useSearchParams();
    const userType = searchParams.get("t")

    const navigate = useNavigate()

    const { register, isLoading } = useAuth()

    const [firstname, setFirstname] = useState('')
    const [lastname, setLastname] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const [errors, setErrors] = useState({})


    const handleSubmit = async (e) => {
        e.preventDefault()

        setErrors({})

        if (!['barbers', 'clients'].includes(userType)) {
            navigate('/auth')
        }

        const formData = {
            firstname,
            lastname,
            email,
            password,
            type: userType
        };


        const errz = registerFormValidator(formData);
        setErrors(errz);


        if (!isNull(errz)) return;


        register({
            setErrors,
            firstname,
            lastname,
            email,
            password,
            type: userType
        })
    }


    return (
        <div className="my-16 py-4 max-w-6xl mx-auto px-4">
            <div className="w-full max-w-md mx-auto py-20">
                <div className="">
                    <h2 className="textHeader-s">Create an account</h2>
                </div>

                <form onSubmit={handleSubmit} className="w-full flex flex-col gap-4 my-3 py-3">
                    <div className="grid lg:grid-cols-2 grid-cols-1 gap-4">
                        <AuthInput
                            type="text"
                            placeholder="First name"
                            value={firstname}
                            change={(e) => setFirstname(e.target.value)}
                            error={errors.firstname ?? null}
                        />
                        <AuthInput
                            type="text"
                            placeholder="Last name"
                            value={lastname}
                            change={(e) => setLastname(e.target.value)}
                            error={errors.lastname ?? null}
                        />
                    </div>
                    <AuthInput
                        type="email"
                        placeholder="Email"
                        value={email}
                        change={(e) => setEmail(e.target.value)}
                        error={errors.email ?? null}
                    />
                    <AuthInput
                        type="password"
                        placeholder="Password"
                        value={password}
                        change={(e) => setPassword(e.target.value)}
                        error={errors.password ?? null}
                    />
                    
                    <AuthButton
                        type="submit"
                        name="Continue"
                        disabled={isLoading}
                        isLoading={isLoading}
                    />
                </form>

                <div className="">
                    <p className="lg:textBody-m3 textBody-s3 text-fontColor2">You have an account? <Link to="/auth/login" className="hover:underline">Sign in</Link></p>
                </div>
            </div>
        </div>
    )
}