import { Link } from "react-router-dom";

export default function Welcome() {
    return (
        <section
            className="w-full bg-cover bg-center"
            style={{ backgroundImage: 'url("/welcome.jpg")' }}
        >
            <div className="bg-gradient-to-t from-black/90 to-black/60 xl:py-52 lg:py-40 md:py-32 py-20">
                <div className="container mx-auto text-center text-white px-2">
                    <h1 className="textHeader-2xl mb-6 flex flex-col text-center">
                        Easy appointments, happy clients.
                    </h1>
                    <p className="textBody-m2 mb-12">
                        Effortlessly manage your appointments and streamline your schedule. Save time, stay organized, and let clients book with ease!
                    </p>
                    <Link
                        to="/auth/register?t=barbers"
                        className="textBody-m2 bg-white text-primary py-4 px-12 rounded-full hover:px-20 transition-all duration-200"
                    >
                        Get Started
                    </Link>
                </div>
            </div>
        </section>
    )
}