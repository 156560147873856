import { useNavigate } from "react-router-dom";
import AvatarLoader from "../../../Shared/Avatar/AvatarLoader";
import { useState } from "react";
import ModalLayout from "../../../../layouts/ModalLayout"
import { Spinner } from "../../../../icons";
import { getFullName, renderImage } from "../../../../Helpers/utils";
import Avatar from "../../../Shared/Avatar";


export default function AvatarDropDown({ authUser, logout, inProgress }) {

    const { role, avatar } = authUser?.data ?? {};

    const navigate = useNavigate()

    const [isOpen, setIsOpen] = useState(false)
    const toggleDropDown = () => setIsOpen(!isOpen)

    const GorTo = (path) => {
        navigate(path)
        toggleDropDown()
    }

    return authUser.isLoading ? <AvatarLoader size={'lg:w-10 lg:h-10 w-8 h-8'} /> : (
        <div className="relative">
            <div onClick={toggleDropDown} className="flex items-center gap-1 cursor-pointer">
                <Avatar
                    src={renderImage(avatar)}
                    size={'lg:w-10 lg:h-10 w-8 h-8'}
                    alt={getFullName(authUser?.data ?? {})}
                />
            </div>

            {isOpen && (
                <ModalLayout
                    wallBackground={`bg-black/10`}
                    className={`absolute ltr:right-0 rtl:left-0 z-[1012]`}
                    close={toggleDropDown}
                >
                    <div className="shadow-lg bg-white animation-01 animation-02 w-[260px] h-full rounded-lg overflow-hidden pb-2 flex flex-col textBody-s3">
                        <h3 className="px-4 line-clamp-1 textBody-s1 border-b border-fontColor2/20 pt-4 pb-4 mb-2">
                            {getFullName(authUser?.data ?? {})}
                        </h3>

                        {role === "member" && (
                            <>
                                <button
                                    onClick={() => GorTo('/dashboard/account')}
                                    className="text-start whitespace-nowrap py-2 px-4 hover:bg-primary/5 transition-colors duration-300">
                                    Account
                                </button>
                                <button
                                    onClick={() => GorTo('/dashboard/appointments')}
                                    className="text-start whitespace-nowrap py-2 px-4 hover:bg-primary/5 transition-colors duration-300">
                                    Appointments
                                </button>
                            </>
                        )}
                        
                        {role === "client" && (
                            <>
                                <button
                                    onClick={() => GorTo('/account')}
                                    className="text-start whitespace-nowrap py-2 px-4 hover:bg-primary/5 transition-colors duration-300">
                                    Account
                                </button>
                                <button
                                    onClick={() => GorTo('/appointments')}
                                    className="text-start whitespace-nowrap py-2 px-4 hover:bg-primary/5 transition-colors duration-300">
                                    Appointments
                                </button>
                            </>
                        )}

                        <div className="w-full h-[1px] bg-primary/5 my-2"></div>

                        {/* <a href="/" target="_blank" rel="noreferrer" className="flex flex-col">
                            <button
                                className="text-start whitespace-nowrap py-2 px-4 hover:bg-primary/5 transition-colors duration-300">
                                Help Center
                            </button>
                        </a> */}

                        <button
                            disabled={inProgress}
                            onClick={() => {
                                logout()
                            }}
                            className={`text-start whitespace-nowrap py-2 px-4 transition-colors duration-300 flex items-center justify-between ${inProgress ? 'bg-primary/5' : 'hover:bg-primary/5'}`}>
                            <span>Logout</span>
                            {inProgress ? <Spinner className="w-4 h-4 animate-spin" /> : null}
                        </button>
                    </div>
                </ModalLayout>
            )}
        </div>
    )
}