import { useState } from "react";
import { ArrowBottom1, Spinner } from "../../../icons";
import ModalLayout from "../../../layouts/ModalLayout"
import { useAlerts } from "../../../context/AlertsContext"
import { isNull } from "../../../Helpers/utils";
import { ChangeAppointmentStatus } from "../../../lib/api";


export default function ActionsDropDown({ id, status, refresh }) {

    const { addAlert } = useAlerts()

    const [showDropDown, setShowDropDown] = useState(false)
    const toggleShowDropDown = () => setShowDropDown(!showDropDown)


    const [inProgress, setInProgress] = useState('')

    const handleChangeStatus = async (s) => {

        if (s === status) {
            toggleShowDropDown();
            return;
        }

        setInProgress(s)

        const data = {
            status: s,
            appointment: id,
        };

        const { is_ok, message, type } = await ChangeAppointmentStatus(data);

        setInProgress('')

        if (type === "validation") {
            //
        } else {
            addAlert(message ?? (is_ok ? 'Success!' : 'Something was wrong!'), is_ok ? 'success' : 'error');
        }

        if (is_ok) {
            refresh();
            toggleShowDropDown();
        }
    }

    return (
        <div className="relative">
            <button
                onClick={toggleShowDropDown}
                className={`textBody-xs2 px-2 py-0.5 rounded-xl border flex items-center gap-1 outline-none ${renderAppoBadgeStatus(status)}`}>
                {status}
                <ArrowBottom1 className="w-4 h-4" />
            </button>

            {showDropDown && (
                <ModalLayout
                    wallBackground={`bg-black/10`}
                    className={`absolute ltr:right-0 rtl:left-0 z-[1019]`}
                    close={toggleShowDropDown}
                >
                    <div className="shadow-lg bg-white animation-01 animation-02 w-[160px] h-full rounded-lg overflow-hidden py-2 flex flex-col textBody-s3">
                        <button
                            disabled={!isNull(inProgress)}
                            onClick={() => handleChangeStatus('active')}
                            className={`text-start whitespace-nowrap py-2.5 px-4 flex items-center justify-between ${renderAppoStatus('active')}`}>
                            <span>Active</span>
                            {!isNull(inProgress) && inProgress === "active" && <Spinner className="w-4 h-4 animate-spin" />}
                        </button>
                        <button
                            disabled={!isNull(inProgress)}
                            onClick={() => handleChangeStatus('confirmed')}
                            className={`text-start whitespace-nowrap py-2.5 px-4 flex items-center justify-between ${renderAppoStatus('confirmed')}`}>
                            <span>Confirmed</span>
                            {!isNull(inProgress) && inProgress === "confirmed" && <Spinner className="w-4 h-4 animate-spin" />}
                        </button>
                        <button
                            disabled={!isNull(inProgress)}
                            onClick={() => handleChangeStatus('canceled')}
                            className={`text-start whitespace-nowrap py-2.5 px-4 flex items-center justify-between ${renderAppoStatus('canceled')}`}>
                            <span>Canceled</span>
                            {!isNull(inProgress) && inProgress === "canceled" && <Spinner className="w-4 h-4 animate-spin" />}
                        </button>
                        <button
                            disabled={!isNull(inProgress)}
                            onClick={() => handleChangeStatus('completed')}
                            className={`text-start whitespace-nowrap py-2.5 px-4 flex items-center justify-between ${renderAppoStatus('completed')}`}>
                            <span>Completed</span>
                            {!isNull(inProgress) && inProgress === "completed" && <Spinner className="w-4 h-4 animate-spin" />}
                        </button>
                    </div>
                </ModalLayout>
            )}
        </div>
    )
}

const renderAppoBadgeStatus = (stts) => {
    switch (stts) {
        case 'active':
            return "text-green-500 border-green-200 bg-green-100";
        case 'confirmed':
            return "text-blue-500 border-blue-200 bg-blue-100";
        case 'canceled':
            return "text-red-500 border-red-200 bg-red-100";
        case 'completed':
            return "text-slate-500 border-slate-200 bg-slate-100";
        default:
            return "text-blue-500 border-blue-200 bg-blue-100";
    }
}

const renderAppoStatus = (stts) => {
    switch (stts) {
        case 'active':
            return "text-fontColor2/80 hover:text-green-500";
        case 'confirmed':
            return "text-fontColor2/80 hover:text-blue-500";
        case 'canceled':
            return "text-fontColor2/80 hover:text-red-500";
        case 'completed':
            return "text-fontColor2/80 hover:text-slate-500";
        default:
            return "text-fontColor2/80 hover:text-blue-500";
    }
}