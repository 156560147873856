import { useNavigate } from "react-router-dom";
import AvatarLoader from "../Shared/Avatar/AvatarLoader";
import { useState } from "react";
import Avatar from "../Shared/Avatar"
import { renderImage } from "../../Helpers/utils";
import ModalLayout from "../../layouts/ModalLayout"
import { ArrowBottom1, Spinner } from "../../icons";


export default function AvatarDropDown({ authUser, logout, inProgress }) {

    const navigate = useNavigate()

    const [isOpen, setIsOpen] = useState(false)
    const toggleDropDown = () => setIsOpen(!isOpen)

    const GorTo = (path) => {
        navigate(path)
        toggleDropDown()
    }

    return authUser.isLoading ? <AvatarLoader size={'lg:w-10 lg:h-10 w-8 h-8'} /> : (
        <div className="relative">
            <div onClick={toggleDropDown} className="flex items-center gap-1 cursor-pointer">
                <Avatar
                    src={renderImage(authUser?.data?.avatar)}
                    size={'lg:w-10 lg:h-10 w-8 h-8'}
                    alt={`${authUser?.data?.firstname ?? '#'} ${authUser?.data?.lastname ?? '#'}`}
                />
                <ArrowBottom1 className="w-4 h-4" />
            </div>

            {isOpen && (
                <ModalLayout
                    wallBackground={`bg-black/20`}
                    className={`absolute ltr:right-0 rtl:left-0 z-[1012]`}
                    close={toggleDropDown}
                >
                    <div className="shadow-lg bg-white animation-01 animation-02 w-[300px] h-full rounded-lg overflow-hidden pb-2 flex flex-col textBody-s3">
                        <h3 className="px-4 line-clamp-1 textBody-s1 border-b border-fontColor2/10 pt-4 pb-4 mb-2">
                            {`${authUser?.data?.firstname ?? '#'} ${authUser?.data?.lastname ?? '#'}`}
                        </h3>
                        <button
                            onClick={() => GorTo('/dashboard/account')}
                            className="text-start whitespace-nowrap py-2.5 px-4 hover:bg-primary/5 transition-colors duration-300">
                            Account Details
                        </button>
                        <button
                            onClick={() => GorTo('/dashboard/salon')}
                            className="text-start whitespace-nowrap py-2.5 px-4 hover:bg-primary/5 transition-colors duration-300">
                            Salon Details
                        </button>

                        <div className="w-full h-[1px] bg-primary/5 my-2"></div>

                        {/* <a href="/" target="_blank" rel="noreferrer" className="flex flex-col">
                            <button
                                className="text-start whitespace-nowrap py-2.5 px-4 hover:bg-primary/5 transition-colors duration-300">
                                Help Center
                            </button>
                        </a> */}

                        <button
                            disabled={inProgress}
                            onClick={() => {
                                logout()
                            }}
                            className={`text-start whitespace-nowrap py-2.5 px-4 transition-colors duration-300 flex items-center justify-between ${inProgress ? 'bg-primary/5' : 'hover:bg-primary/5'}`}>
                            <span>Logout</span>
                            {inProgress ? <Spinner className="w-4 h-4 animate-spin" /> : null}
                        </button>
                    </div>
                </ModalLayout>
            )}
        </div>
    )
}