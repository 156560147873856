import { getFullName, isNull, n, renderImage } from "../../../Helpers/utils";
import Avatar from "../../Shared/Avatar"
import ActionsDropDown from "./ActionsDropDown";


export default function AppointmentCard({ selectedApp, selectApp, appo, refresh }) {

    const { id, booking_date, booking_time, booking_status, client } = appo;

    const isSelected = !isNull(selectedApp) && n(selectedApp) === id;

    return (
        <div className={`rounded-lg py-4 px-6 select-none transition-colors duration-200 ${isSelected ? 'bg-primary/[0.02] border border-primary/5' : 'hover:bg-primary/[0.03] border border-borderColor hover:border-primary/5'}`}>
            <div className="flex justify-between">
                <div onClick={() => selectApp(id)} className="cursor-pointer w-full">
                    <div className="flex items-center gap-2">
                        <div>
                            <Avatar
                                src={!isNull(client?.avatar) ? renderImage(client?.avatar) : ''}
                                size={'w-8 h-8'}
                                alt={'avatar'}
                            />
                        </div>
                        <div className="flex flex-col">
                            <span className="textBody-s2 text-fontColor line-clamp-1">{getFullName(client)}</span>
                            <div className="text-fontColor2">
                                <span className="textBody-xs3 inline-block mr-2">{booking_date}</span>
                                <span className="textBody-xs3 inline-block">{booking_time}</span>
                            </div>
                        </div>
                    </div>

                    {/* // */}
                </div>

                <div className="">
                    <ActionsDropDown
                        id={id}
                        status={booking_status}
                        refresh={refresh}
                    />
                </div>
            </div>
            <div onClick={() => selectApp(id)} className={`cursor-pointer pt-4 mt-4 border-t border-borderColor transition-all duration-300 ${isSelected ? 'block' : 'hidden'}`}>
                <div className="">
                    <span className="textBody-s2 text-fontColor border-b pb-1">Details</span>
                </div>
                <div className="ml-0 mt-2 textBody-xs3">

                    <div className="grid grid-cols-2 gap-1 pt-3">
                        <div className="">
                            <span className="text-fontColor">Booked by:</span>
                        </div>
                        <div className="">
                            <span className="text-fontColor2">{getFullName(client)}</span>
                        </div>
                    </div>
                    <div className="grid grid-cols-2 gap-1 pt-3">
                        <div className="">
                            <span className="text-fontColor">Booking date:</span>
                        </div>
                        <div className="">
                            <span className="text-fontColor2">{booking_date}</span>
                        </div>
                    </div>
                    <div className="grid grid-cols-2 gap-1 pt-3">
                        <div className="">
                            <span className="text-fontColor">Booking time:</span>
                        </div>
                        <div className="">
                            <span className="text-fontColor2">{booking_time}</span>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}