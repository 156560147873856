import { Link } from "react-router-dom";
import { useAuth } from "../../../../hooks/useAuth";
import AvatarDropDown from "./AvatarDropDown";


export default function Header() {

    const { authUser, isAuth, logout, isLoading } = useAuth();

    const { role } = authUser?.data ?? {};

    // handle logout click
    const handleLogout = async () => {
        await logout()
    }

    return (
        <header className="w-full border-b">
            <div className="max-w-6xl mx-auto h-[65px] w-full px-4 flex items-center justify-between">
                <Link to="/">
                    <h1 className="textBody-ml1">Sharely</h1>
                </Link>


                {!isAuth && (
                    <div className="flex items-center gap-4">
                        <Link to="/auth/login" className="hover:underline textBody-s2">Login</Link>
                        <Link to="/auth" className="px-6 py-2.5 hover:bg-primary2 textBody-s2 bg-primary text-white rounded-full transition-colors duration-200">Register</Link>
                    </div>
                )}


                {isAuth && (
                    <div className="flex items-center gap-4">

                        {role === "client" && (
                            <Link to="/appointments">
                                <button
                                    className="flex items-center gap-1 bg-transparent hover:underline text-[#2d54de] cursor-pointer outline-none">
                                    <span className="">Appointments</span>
                                </button>
                            </Link>
                        )}

                        {role === "member" && (
                            <Link to="/dashboard">
                                <button
                                    className="flex items-center gap-1 bg-transparent hover:underline text-[#2d54de] cursor-pointer outline-none">
                                    <span className="">Dashboard</span>
                                </button>
                            </Link>
                        )}

                        <AvatarDropDown authUser={authUser} logout={handleLogout} inProgress={isLoading} role={role} />
                        {/* {authUser.isLoading
                            ? <AvatarLoader size={'lg:w-10 lg:h-10 w-8 h-8'} />
                            : <Avatar
                                src={renderImage(avatar)}
                                size={'lg:w-10 lg:h-10 w-8 h-8'}
                                alt={`${firstname ?? '#'} ${lastname ?? '#'}`}
                                style={{ cursor: 'pointer' }}
                                href={role === "client" ? '/account' : role === "member" ? '/dashboard/account' : ''}
                            />} */}
                    </div>
                )}
            </div>
        </header>
    )
}