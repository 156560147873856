import { fetchData, useSWRFetch } from "../hooks/useFetch";


/**
 * All api requests in one place
 */

// export const GetAllPublicSalons = (params) => useSWRFetch(`api/GetAllPublicSalons`, 'GET', params);
export const GetSalonDataBySlug = (params) => useSWRFetch(`api/GetSalonDataBySlug`, 'GET', params, {}, true);

export const GetAllAppointmentsBySalon = (params) => useSWRFetch(`api/GetAllAppointmentsBySalon`, 'GET', params);
export const GetAllAppointmentsByClient = (params) => useSWRFetch(`api/GetAllAppointmentsByClient`, 'GET', params);

export const GetSalonByMember = (params) => useSWRFetch(`api/GetSalonByMember`, 'GET', params);

export const UpdateSalonBaseData = (data, params) => fetchData(`api/UpdateSalonBaseData`, 'PUT', params, data);

export const UpdateUserBaseData = (data, params) => fetchData(`api/UpdateUserBaseData`, 'PUT', params, data);
export const UpdateUserPassword = (data, params) => fetchData(`api/UpdateUserPassword`, 'PUT', params, data);
export const UpdateUserContacts = (data, params) => fetchData(`api/UpdateUserContacts`, 'PUT', params, data);

export const NewAppointment = (data, params) => fetchData(`api/NewAppointment`, 'POST', params, data);
export const ChangeAppointmentStatus = (data, params) => fetchData(`api/ChangeAppointmentStatus`, 'POST', params, data);