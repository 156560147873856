import React, { useCallback, useState } from "react";
import { useEffect } from "react";
import { Spinner } from "../../../icons";
import { useAuth } from "../../../hooks/useAuth";
import { isNull, removeDomainFromUrl } from "../../../Helpers/utils";
import axiosClient from "../../../lib/axiosClient";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import useCookies from "../../../hooks/useCookies";


export default function CheckEmail() {

    const { getCookie, removeCookie } = useCookies()

    const backToUrlValue = getCookie("backto") ?? "";

    const navigate = useNavigate()


    // const handleNavigate = (userRole) => {
    //     if (userRole === "member") {
    //         navigate('/dashboard/salon');
    //     } else {
    //         removeCookie("backto")
    //         navigate(removeDomainFromUrl(backToUrlValue));
    //     }
    // }
    const handleNavigate = useCallback((userRole) => {
        if (userRole === "member") {
            navigate('/dashboard/salon');
        } else {
            removeCookie("backto");
            navigate(removeDomainFromUrl(backToUrlValue));
        }
    }, [navigate, removeCookie, backToUrlValue]);


    const [searchParams] = useSearchParams();
    const token = searchParams.get("token")
    const email = searchParams.get("email")


    const { csrf, authUser } = useAuth()


    const { data: user, isLoading } = authUser ?? {};


    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState([])


    useEffect(() => {
        const sendVerificationRequest = async () => {
            try {
                setLoading(true);
                setErrors([]);

                await csrf();

                const { data } = await axiosClient.post('api/auth/confirmation/email/checkLink', { token, email });

                if (data?.error) {
                    setErrors({ danger: 'Email confirmation failed!' });
                } else {
                    setErrors({ success: 'Your email has been successfully confirmed!' });
                    handleNavigate(user?.role)
                }

                setLoading(false);
            } catch (err) {
                setErrors({ danger: 'Email confirmation failed!' });
                setLoading(false);
            }
        };

        if (!isNull(user) && !isNull(token) && !isNull(email) && user?.isEmailVerified === 0 && !loading && isNull(errors)) {
            sendVerificationRequest();
        }
    }, [user, token, email, loading, csrf, handleNavigate, errors]);


    return (
        <section className="min-h-screen flex flex-col items-center justify-center">
            {isLoading || loading ? (
                <Spinner className="w-12 h-12 animate-spin" />
            ) : (
                <div className="flex flex-col gap-4">
                    {errors.danger && (
                        <div className="flex flex-col gap-4 text-center">
                            <div className="bg-red-500 text-white py-2 lg:py-4 px-2 sm:px-4 lg:px-6 w-full rounded-lg">
                                <span>{errors.danger}</span>
                            </div>
                            <Link to="/auth/confirmation/email">
                                <button className="rounded-lg py-3 px-6 bg-primary2 border border-primary text-white hover:opacity-90 lg:text-base text-sm font-bold transition-all duration-200 w-full">
                                    re-send verification link
                                </button>
                            </Link>
                        </div>
                    )}
                    {errors.success && (
                        <div className="flex flex-col gap-4 text-center">
                            <div className="bg-green-500 text-white py-2 lg:py-4 px-2 sm:px-4 lg:px-6 w-full rounded-lg">
                                <span>{errors.success}</span>
                            </div>
                            <Link to="/">
                                <button className="rounded-lg py-3 px-6 bg-primary2 border border-primary text-white hover:opacity-90 lg:text-base text-sm font-bold transition-all duration-200 w-full">
                                    Go to home page
                                </button>
                            </Link>
                        </div>
                    )}
                </div>
            )}
        </section>
    );
};