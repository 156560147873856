import { Link } from "react-router-dom";
import { Menu } from "../../icons";
import AvatarDropDown from "./AvatarDropDown";
import { useAuth } from "../../hooks/useAuth";


export default function NavBar() {

    const { authUser, logout, isLoading } = useAuth();

    // handle logout click
    const handleLogout = async () => {
        await logout()
    }

    return (
        <nav className="sticky top-0 w-full lg:min-h-[70px] min-h-[60px] bg-white flex items-center justify-between flex-row border-b border-primaryBorder px-0 py-0 z-[1001]">
            <div className="flex items-center max-lg:px-3">
                <div className="flex items-center max-lg:ltr:pr-3 max-lg:rtl:pl-3 lg:min-w-[200px]">
                    <div className="p-3 max-lg:hidden">
                        <button
                            className="flex shrink-0 justify-center items-center w-10 h-10 bg-transparent rounded-full text-primaryTextColor cursor-pointer outline-none btn_effect">
                            <Menu className="lg:w-6 lg:h-6 w-5 h-5" />
                        </button>
                    </div>
                    <Link to={'/'} className="w-fit">
                        <h1 class="textBody-ml1 text-fontColor2 hover:text-fontColor">Sharely</h1>
                    </Link>
                </div>

                {/* // */}
            </div>


            <div className="flex items-center gap-2 px-3">
                {/* <div className="flex items-center">
                    <button
                        className="flex shrink-0 justify-center items-center w-10 h-10 bg-transparent rounded-full text-primaryTextColor cursor-pointer outline-none btn_effect">
                        <Message className="lg:w-6 lg:h-6 w-5 h-5" />
                    </button>

                    <button
                        className="flex shrink-0 justify-center items-center w-10 h-10 bg-transparent rounded-full text-primaryTextColor cursor-pointer outline-none btn_effect">
                        <Notification className="lg:w-6 lg:h-6 w-5 h-5" />
                    </button>
                </div> */}

                <AvatarDropDown authUser={authUser} logout={handleLogout} inProgress={isLoading} />
            </div>
        </nav>
    )
}